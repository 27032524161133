import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import DynamicZone from '../components/DynamicZone/DynamicZone'

export default function AboutUsPage({ data: { strapi: { aboutUs } }, location }) {
	
	return (
		<>
			<SEO title="About Us" description={ aboutUs.seoDescription } pathname={ location.pathname } />

			{ aboutUs && aboutUs.content &&
				<DynamicZone components={ aboutUs.content } />
			}
		</>
	)
}

export const query = graphql`
	query aboutUsQuery {
		strapi {
			aboutUs {
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentAboutAboutInfoSmall {
						id
						aboutInfoSmallTitle
						aboutInfoSmallDescription
						aboutInfoSmallReverse
						aboutInfoSmallImage {
							id
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 750, maxHeight: 750) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentAboutAboutInfoLarge {
						id
						aboutInfoLargeTitle
						aboutInfoLargeDescription
						aboutInfoLargeImage {
							id
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 750, maxHeight: 750) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentAboutTimeline {
						show
					}
					... on StrapiQuery_ComponentAboutWiderFamily {
						id
						heading
						families {
							id
							logo
							main
							name
							summary
							url
						}
					}
					... on StrapiQuery_ComponentAboutTeamMembersList {
						id
						heading
						members {
							description
							id
							name
							role
							image {
								url
								imageFile {
									childImageSharp {
										fluid(maxWidth: 400) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentCommonVideo {
						id
						videoNumber
						videoTitle
					}
				}
			}
		}
	}
`